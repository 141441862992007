/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Thumbnail from "../images/thumbnail.png"

function SEO({ description, lang, meta, title, image, pageType }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const disableBundle =
    title === "Native Advertising vs Sponsored Content: What’s the Difference?"

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: "theme-color",
          content: "#190241",
        },
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:image",
          content: image || Thumbnail,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: pageType || "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: site.siteMetadata.author,
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
        {
          name: "google-site-verification",
          content: "sg74inu3b2a9_jQhpJVCjJlom9daF79YSGoTzqsxZEc",
        },
      ].concat(meta)}
    >
      {disableBundle ? (
        [
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=DC-13891859"
          ></script>,
          <script>{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'DC-13891859');`}</script>,
          <script>{`gtag('event', 'conversion', {'allow_custom_scripts': true,'send_to': 'DC-13891859/invmedia/15secs+standard'});`}</script>,
          <noscript>
            {`<img
              src="https://ad.doubleclick.net/ddm/activity/src=13891859;type=invmedia;cat=15secs;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=1?"
              width="1"
              height="1"
              alt=""
            />`}
          </noscript>,
        ]
      ) : (
        <script
          src="https://cdn.inpwrd.net/track/1.0.0/bundle.min.js?customerId=435&siteId=382568903207&trackConversions=false"
          type="text/javascript"
        />
      )}
      {/* DV360 GTag */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=DC-14787405"
      ></script>
      <script>{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'DC-14787405');`}</script>
      <script type="text/javascript">
        {`_linkedin_partner_id = "3937172"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
      </script>
      <script type="text/javascript">
        {`(function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);`}
      </script>
      <noscript>
        {`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=3937172&fmt=gif"/>`}
      </noscript>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-1018747664"
      ></script>
      <script>{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-1018747664');`}</script>
      {/* HubSpot Embed Code */}
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/39508450.js"
      ></script>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"
      ></link>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
